export default {
    //自定义节流操作
    my_one_click: {
        mounted (el, binding) {
            el.addEventListener('click', () => {
                if (!el.disabled) {
                    el.disabled = true;
                    setTimeout(() => {
                        el.disabled = false;
                    }, binding.value || 1000);
                }
            })
        }
    },
    adaptive: {
        mounted: (el, binding) => {
            el.resizeListener = () => {
                setHeight(el, binding)
            }
            setHeight(el, binding)
            window.addEventListener('resize', el.resizeListener)
        },
        updated (el, binding) {
            setHeight(el, binding)
        },
        unmounted (el) {
            window.removeEventListener('resize', el.resizeListener)
        }
    },
}

function setHeight (el, binding) {
    const top = el.offsetTop
    const bottom = 100
    const pageHeight = window.innerHeight
    el.style.height = pageHeight - top - bottom + 'px'
    el.style.overflowY = 'auto'
}

