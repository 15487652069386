import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from "vue-cookies"
import smart from 'address-smart-parse'
import installElementPlus from './plugins/element'
import saveAs from 'file-saver'
import http from "./utils/http";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from 'axios'
const app = createApp(App)
import dir from "./directive/index";
import EventBus from './eventBus/myEventBus'
// 或者：import EventBus from 'mitt'
const $bus = new EventBus()

// ② 挂载
// 1.使用provide提供
app.provide('$bus', $bus)
// 2.挂载到this上
app.config.globalProperties.$bus = $bus
app.directive("myOneClick", dir.my_one_click);
app.directive("adaptive", dir.adaptive);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
import { ElCollapseTransition } from 'element-plus'
import 'element-plus/lib/theme-chalk/base.css'
app.component(ElCollapseTransition.name, ElCollapseTransition)
installElementPlus(app)

// 服务器前缀设
// const baseURL = '/api'
const baseURL = ''  

axios.defaults.baseURL = baseURL
axios.defaults.withCredentials = true
app.config.globalProperties.$axios = axios
app.config.globalProperties.$cookies = VueCookies
app.config.globalProperties.$smart = smart
app.config.globalProperties.$saveAs = saveAs

app.provide('http', { http })

app.use(store).use(router).mixin({
    data () {
        return { baseURL }
    }
}).mount('#app')

                                                                                                         