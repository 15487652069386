<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import linkPost from "@/utils/new_link";
const store = useStore();
const router = useRouter()


const token = ref("");
const select = ref("");
const handleMouseMove = (event) => {
  select.value = event;
};
const handleMouseMove1 = (event) => {
  select.value = "";
};
const loglist = ref([]);
const openlog = async () => {
  let res = await linkPost(
    "POST",
    "/admin.api.user/getChildAccount",
    token.value
  );
  if (!res.data.code) {
    loglist.value = res.data;

  }
};
const login = async (e) => {
  let res = await linkPost("POST", "/admin.api.user/login", token.value, {
    phone: e.phone,
    captcha: 708215,
    merchant_id: e.merchant_id,
  });
  if (res.code == 0) {
    ElMessage({
      message: res.msg,
      type: "success",
    });
    store.commit("loginSuccess", res.data);
    window.localStorage.setItem("token", res.data.token);
    window.sessionStorage.setItem("login_user", JSON.stringify(res.data));
    window.sessionStorage.setItem("cjqx", res.data.is_super);
    $cookies.set("token", res.data.token, 3 * 24 * 60 * 60);
    router.push('/service')
  }
};
onMounted(() => {
  token.value = { "laytp-admin-token": window.localStorage.getItem("token") };
  openlog();
});
</script>
<template>
  <div class="box">
    <img
      alt=""
      src="../assets/img/bjimg.png"
      style="width: 100%; height: 400px"
    />
    <div style="background: rgb(244, 245, 250); padding: 0px 22px">
      <div
        style="
          background-color: #ffffff;
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 40px;
        "
      >
        <div
          v-for="(item, index) in loglist"
          :key="index"
          :class="select == item.index ? 'bj1' : 'bj'"
          :style="item.is_select == true ? 'border: 1px solid #0551FF;' : ''"
          class="bj"
          @click="login(item)"
          @mouseenter="handleMouseMove(item.index)"
          @mouseleave="handleMouseMove1()"
        >
          <img
            :src="item.logo_url"
            alt=""
            style="
              width: 95px;
              height: 95px;
              margin-left: 39px;
              margin-top: 13px;
            "
          />
          <div
            style="
              text-align: center;
              margin-top: 20px;
              font-size: 12px;
              color: #686868;
            "
          >
            {{ item.nickname }}
          </div>
          <div
            style="
              text-align: center;
              margin-top: 5px;
              font-size: 12px;
              color: #686868;
            "
          >
            {{ item.phone }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.box {
  width: 100vw;
  background: #fff;
  .bj {
    margin: 20px 0px 0px 30px;
    width: 170px;
    height: 172px;
    background-image: url("../assets/img/background.png");
    background-size: cover;
    /* 背景图像尺寸为 cover */
    background-repeat: no-repeat;
    /* 防止背景图像重复 */
  }

  .bj1 {
    margin: 20px 0px 0px 30px;
    width: 170px;
    height: 172px;
    background-image: url("../assets/img/background.png");
    background-size: cover;
    /* 背景图像尺寸为 cover */
    background-repeat: no-repeat;
    /* 防止背景图像重复 */
    // border: 1px solid #0551FF;
    box-shadow: 0px 6px 7px 0px rgba(204, 220, 255);
  }
}
</style>